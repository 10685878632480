/**
 *  Standard Page Layout Components
 */
html {
   .hidden-desktop {
    display: inherit !important;
   }
   .visible-desktop {
    display: none !important ;
   }
   .visible-tablet {
    display: inherit !important;
   }
   .hidden-tablet {
    display: none !important;
   }
   #responsiveNotifier { 
     border-width: 2px; 
     font-weight: 200;
   }
}
