/**
 *  Standard Page Layout Components
 */
@import "_tabletShared.less";
@color-c21grey: #38383a;

#PageFrame {
   width: 768px;
}
#HeroFrame {
   left: 51px;
}
#dropdown {
   left: 51px;
}
#HeaderNavigation {
   background: url(/images/layout/texturesheet.png) -2px -24px no-repeat;
}
#HeaderActions {
   background: url(/images/layout/texturesheet.png) -2px 0px no-repeat;
}
#PageFooter {
   background: url(/images/layout/pagefooterbg.png ) -2px 0 repeat-y;
}
#FooterNavigation {
   padding: 0 0px 0px 11px;
   background: url(/images/layout/texturesheet.png) -2px -97px no-repeat;
}
#FooterNavigation #websiteFeedbackLink {
   border-left: 2px solid #AFAFAF;
   padding-left: 9px;
   float: none;
}
body.esp #IconSocialMediaContainer,
#IconSocialMediaContainer {
   float: none;
   margin: auto;
   padding: 0 0 10px 0;
   width: 722px;
   height: 35px;
   margin: -22px 12px 0 12px;
   text-align: center;

   position: relative;
   top: auto;
   right: auto;
}
#FooterNavigation a.IconSocialMedia {
   float: none;
   display: inline-block;
}
#FooterSitemap {
   margin-top: 50px;
}
#FooterSitemap .SitemapLane {
   width: 179px;
}
#PageFooter.forcebottom {
   height: 450px;
   position: absolute;
   bottom: -396px;
}
#PageBreadcrumbs {
   background: url(/images/layout/texturesheet.png) -2px -151px no-repeat;
}
#HeaderLoggedIn,
#HeaderActions #siteLinks {
   letter-spacing: -1px;
}
#HeaderActions a {
   letter-spacing: 0;
}



/**
 * Calculators
 */
.calculator3Lane {
   background: url(/images/calculator/pagebodybg_calculator3lane.png ) -2px 0 repeat-y;
}
#calculator_chart {
   display: none;
}
.calculator3Lane #BodyLeftLane {
   padding: 0;
}
.calculator3Lane #BodyRightLane {
   width: 285px;
}
.calculator3Lane #BodyCenterLane .LaneTitle.TitleColspan {
   width: 538px;
}
#calculatorResults {
   width: 257px;
   padding: 0px 15px;
}

/**
 * Content
 */
.Standard1Lane {
   background: url(/images/layout/pagebodybg_standard1lane.png ) -2px 0 repeat-y;
}
.Standard2LaneLeft {
   background: url(/images/layout/pagebodybg_standard2laneleft.png ) -2px 0 repeat-y;
}
.Standard2LaneRight {
   background: url(/images/layout/pagebodybg_standard2laneright.png ) -2px 0 repeat-y;
}
.Standard1Lane .LaneTitle {
   margin-left: 0;
}
.Standard2LaneRight #BodyRightLane .LaneTitle {
   background: @color-c21grey; // #1f1f1f;
   clear: both;
}
.Standard3Lane {
   background: url(/images/layout/pagebodybg_standard3lane.png ) -2px 0 repeat-y;
}
.Standard3Lane #BodyLeftLane {
   padding-left: 0;
}
.Standard3Lane #BodyCenterLane {
   width: 588px;
}
.Standard3Lane #BodyCenterLane .LaneTitle.TitleColspan {
   width: auto;
}
.contentArea {
   padding: 10px 10px 69px;
}
.contentBody {
   width: 520px;
   border-right: 0;
}
.Standard2LaneLeft #BodyLeftLane {
   padding: 0;
}
.Standard2LaneLeft #BodyRightLane {
   width: 568px;
   padding: 0;
}
.Standard2LaneRight #BodyLeftLane {
   width: 768px;
   padding: 0;
}
.Standard2LaneRight #BodyRightLane {
   width: 768px;
   padding: 0;
   min-height: 0;
}
.contentNavigation {
   width: 190px;
}
.contentBody.threeColumn {
   width: 520px;
   padding-right: 0;
   border-right: 0;
}
.contentRightLane {
   width: 500px;
   margin-left: 200px;
   margin-top: 20px;
}
.contentBody.threeColumn img {
   max-width: 100%;
}
#glossaryIndexWrapper {
   display: none;
}
.calloutclips {
   width: auto;
}
#PageBody.contentArea .contentBody {
   width: 540px !important;
}
.contentBodySection .commercialThumb {
   margin: 36px 0 50px 28px;
}


/**
 * Contact Forms
 */
#generalQuestionForm,
#generalQuestionInfo,
#contactCareerForm,
#contactFranchiseForm {
   float: none;
}

/**
 * Find a home
 */


#findAHomeSearchTabs {
   width: 768px;
   height: 75px;
   margin-left: 0;
}
#findAHomeSearchTabs .searchTab img {
   max-width:100%;
}
#findAHomeSearchTabs .searchTab {
   width: 252px;
}
#findAHomeSearchTabs .searchTab.bypropfeatures {
   margin-left: 3px;
}
#findAHomeSearchTabs .searchTab.bymap {
   margin-left: 258px;
}
#findAHomeSearchTabs .searchTab.byschool {
   margin-left: 513px;
}
.findAHomeBody {
   width: 768px;
}
.findHomeSearchInputs {
   width: 364px;
}
.findHomePropLabel {
   display: block;
}
.findAHomeBody #findAHomePropertyDetails #propertyAdditional {
   width: 746px;
   margin-left: 0;
}
.findAHomeSearchOptions {
   width: 748px;
}
.findAHomeBody .findAHomeDropdown {
   width: 728px;
}
.multipleResultColumn {
   width: 146px;
}
#propertyTypes {
   width: 195px;
   height: 330px;
   padding-right: 10px;
}
#propertySize {
   width: 204px;
}
#propertySize .propSizeBox {
   margin: -4px 10px 0 10px;
}
#listingTypes {
   width: 289px;
}
.listingTypeBox .listingTypeRow {
   margin-left: 0px;
}
#propertyEssential.propertyEssentialBox {
   width: 717px;
}
.findHomePropDetails {
   width: 700px;
}
.findHomePropDetails .propBedrooms {
   border-right: 0;
}
.findHomeBtm {
   width: 731px;
}
.propDemographicHdr ul {
   width: 748px;
}

#listTypeForSaleRadioDiv,
#listTypeNewListingsDiv,
#listTypeOpenHousesRadioDiv {
   display: block;
}
.findAHomeBody .locationSearchInformation .info {
   margin: 0;
}
#searchByMapDiv.searchByMapContainer {
   width: 768px;
   height: 400px;
}
#findASchoolMainSearch {
   width: 733px;
}

#findASchoolMainSearch #findASchoolSearch {
   width: 558px;
}
#findASchoolSearch .locationSearch {
   width: 543px;
}
#findASchoolSearch .schoolNameSearch {
   width: 558px;
}
#findASchoolMainSearch .locationRequiredInfo {
   margin: 5px 0px 0px 290px;
}
#findASchoolSearch .findHomeSearchInputs {
   width: 312px;
   margin-right: 10px;
}
.findHomeSearchInputs input {
   width: 300px;
}
#findAHomeBySchoolBody .infoLabel {
   margin-left: 7px;
}
#findAHomeBySchoolBody #noSchoolSearchResultsSection .actionSection {
   margin: 40px 0px 0px 157px;
}
#findAHomeBySchoolBody #schoolSearchResultsSection .schoolRecord {
   width: 738px;
}
#findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane1 {
   width: 320px;
   margin: 10px 0px 0px 5px;
}
#findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane2 {
   width: 215px;
   margin: 10px 0px 0px 330px;
}
#findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane3 {
   width: 170px;
   margin: 10px 0px 0px 550px;
}
#searchByMapDiv #nationalmap {
   width: 725px;
   height: auto;
}
.stateHeader {
   font-size: 16px;
}
.stateInformationDiv .stateLink {
   padding: 18px 0 0 15px;
   float: left;
   width: 129px;
}
.stateInformationDiv .stateLink {
   padding: 18px 0 0 15px;
   float: left;
   width: 107px;
   cursor: pointer;
   color: #333;
}
#listingLaneContainer .wideSitemapLane .stateInformationDiv .stateLink.wide {
   width: 214px;

}
#listingLaneContainer .wideSitemapLane .stateInformationDiv .stateLink.wide a {
   font-size: 12px;
}



/**
 * Properties
 */
#propertyTabHolder {
   margin: 0;
}
.propertyTabBody {
   width: 768px;
}
#PropDetailBg {
   background: url(/images/layout/propertydetails.png) -205px -40px no-repeat;
   width: 768px;
}
.propViewNavbar {
   width: 748px;
}
#PropViewBg {
   width: 768px;
}
.propSchoolsTypeContainer {
   width: 758px;
   padding: 5px;
}
.propSchoolsTable {
   width: 748px;
}
#similarProperties {
   background: #e9e3db;
   height: 148px;
   overflow: hidden;
}
.RightLanePaginator {
   position: relative;
   padding-left: 25px;
}
.RightLaneCard.propertyCard {
   background: #e9e3db;
   width: 211px;
   height: 128px;
   border-top: 0;
   float: left;
   border-right: 1px solid #bcae9c;
}
.RightLaneCard:hover {
   background: #f7f5f2;
}
#BodyRightLane a.PaginatorUp {
   width:25px;
   height: 148px;
   position: absolute;
   left: 0;
   top: 0;
   text-align: center;
   line-height: 148px;
   background: #e9e3db;
   border-right: 1px solid #bcae9c;
}
#BodyRightLane a.PaginatorDown {
   width:26px;
   height: 148px;
   position: absolute;
   right: 0;
   top: 0;
   text-align: center;
   line-height: 148px;
   background: #e9e3db;
   border: 0;
}
#BodyRightLane a.PaginatorDown:hover,
#BodyRightLane a.PaginatorUp:hover {
   width:25px;
   height: 148px;
   background: #f7f5f2;
}
#sectionCalcLoan {
   float: left;
   width: 30%;
   margin-left: 3%;
}
#sectionCalcAnnuals {
   float: left;
   width: 30%;
}
#sectionCalcResults {
   float: left;
   width: 34%;
}
.StandardBtn.calculateButton {
   margin-right: 50px;
}
.propPreviewContainer {
   width: 768px;
}
span#localAreaData  {
   display: block;
   overflow: hidden;
}
#property3DShowcaseTab iframe {
   width: 100%;
   height: 370px;
}

/**
 * Contact Card
 */
#mobileContactCard .LaneTitle {
   display: none;
}
#mobileContactCard {
   background-color: #eae3db;
   border-bottom: 3px solid black;
}
#mobileContactCard #contactCard .contactName,
#mobileContactCard #contactCard .contactName a {
   color: #222;
}
#mobileContactCard .contactThumb {
   display: none;
}
#mobileContactCard #contactCard .rightLaneBgContainer {
   background: none;
   border-bottom: none;
   padding-bottom: 0;
}
#mobileContactCard #contactCard .contactName {
   margin-bottom: 0;
}
#mobileContactCard .contactAddress {
   width: auto;
   margin-left: 0;
   float: none;
   display: inline;
}
#mobileContactCard .contactAddress span.mobileSpace {
   color: #666;
   padding: 0 0.3em;
}
#mobileContactCard .contactAddress br {
   display:none;
}
#mobileContactCard #contactCard .helptext {
   text-align: left;
   margin-top: -20px;
}
#sectionContactName {
   float: left;
}
#sectionContactName {
   float: left;
}
#sectionContactComments {
   float: right;
}
#sectionContactComments textarea {
   height: 43px;
}
#sectionContactContact {
   float: left;
   margin-left: 15px;
}
#sectionContactHow {
   position: absolute;
   left: 400px;
   margin-top: 16px;
}
#sectionContactSubscribe {
   position: absolute;
   left: 400px;
   margin-top: 72px;
}
#sectionContactSubscribe .c21EmailContainer {
   margin-left: -4px;
}
#sectionContactSubmit {
   position: absolute;
   right: 10px;
   // margin-top: 88px;
}
#sectionContactHelp {
   clear: both;
   top: 180px;
   padding-top: 10px;
}
#mobileContactCard hr {
   display: none;
}
#mobileContactCard #contactCard .contactName .multiAgentOfficeName {
   margin-left: 0;
}
#mobileContactCard #contactCard .contactName .contactMultiAgentName {
   line-height: normal;
   margin: 0;
   width: auto;
   padding-left: 10px;
   border-left: 1px solid #666;
   margin-right: 5px;
}
#mobileContactCard #contactCard .contactName .contactMultiAgentName:first-child {
   border-left: 0;
   padding-left: 0;
}



/**
 * Agent Detail Page
 */
.Standard2LaneRight.AgentDetail2LaneRight {
   background: url(/images/layout/pagebodybg_standard3lane.png) -2px 0 repeat-y;
}
.Standard2LaneRight.AgentDetail2LaneRight #BodyRightLane {
   min-height: 0;
}
#agentRightLane {
   width: 558px;
}
#agentRightLane #leftBlock {
   padding-right: 25px;
}
.Standard3Lane.AgentDetail3Lane #BodyRightLane {
   display: none;
}
.CenterLaneCardBg {
   background: url(/images/layout/centerlanecard.png) -2px 0px no-repeat;
   width: 588px;
}
.CenterLaneCardBg:hover {
   background: url(/images/layout/centerlanecard.png) -2px -202px no-repeat;
   width: 588px;
}
#agentContactForm {
   background-color: #eae3dc;
}
#PageBody.AgentDetail2LaneRight {
   padding-bottom: 0;
}


/**
 * Office Detail Page
 */
#officeRightLane {
   padding-right: 7px;
   padding-left: 5px;
}
#officeContactForm {
   background-color: #eae3dc;
}
#PageBody.OfficeDetail2LaneRight {
   padding-bottom: 0;
}
.Standard3Lane.OfficeDetail3Lane #BodyRightLane {
   display: none;
}
.Standard3Lane .BottomPagination {
   width: 588px;
   left: 0;
}


/**
 * Modal Windows
 */
#modalContainer {
   max-width: 600px;
   width: 100%;
}
#modalContainer.contactPropertyBox {
   width: 100%;
   max-width: 810px;
   margin-left: 0;
}
#modalContainer #modalContent {
   margin: 3px;
}
#modalPropertyLeftLane {
   width: 138px;
   padding: 10px;
}
#modalPropertyRightLane {
   background: white;
}

body.esp #HeaderActions #siteLinks {
   font-size: 10px;
}


/* Media Center */
#PageBody .calloutmedia {
   margin: 10px 10px 10px 0px;
}

/**
 * Find A Home
 */
@import "_tabletFindAHome.less";
