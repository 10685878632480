
#PageBody.searchByMapBody .searchByMapContainer,
#PageBody.searchByMapBody #nationalmapContainer,
#PageBody.stateMapLane .returnToLocationBtn,
#PageBody.stateMapLane .stateImg,
#PageBody.stateMapLane .LaneTitle .secondaryLink {
   display: none;
}
#PageBody.stateMapLane h1 {
   font-size: 16px;
}
#PageBody.stateMapLane .searchDiv {
   display: inline-block !important;
   background-color: #a6a5a5;
   height: 74px;
   width: 100%;
}
#PageBody.stateMapLane .searchDiv .searchBtn {
   position: absolute;
   display: inline-block;
   border: 1px solid #ffffff;
   border-radius: 2px;
   background-color: #deddde;
   text-align: center;
   margin: 15px 166px;
   padding: 12px 50px;
   font-size: 16px;
   color: #000;
   cursor: pointer;
}
#PageBody.stateMapLane .statedetailSitemapLane {
   width: 100%;
   margin-left: 0px;
}
#PageBody.stateMapLane .backToState {
   display: inline-block !important;
   font-size: 14px;
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol1 a,
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol2 a {
   font-size: 15px;
}
#PageBody.stateMapLane .stateMapWrapper,
#PageBody.stateMapLane .stateMapWrapper #listingCountiesDiv,
#PageBody.stateMapLane .stateMapWrapper #listingCitiesDiv {
   width: 100%;
   overflow: hidden;
}
#PageBody.stateMapLane .stateMapWrapper #listingCountiesDiv {
   margin: 0px 20px;
}
#PageBody.stateMapLane .stateMapWrapper #listingCitiesDiv {
   margin: 0;
}
#PageBody.stateMapLane #listingLaneCities,
#PageBody.stateMapLane #listingLaneCounties {
   position: relative;
   column-count: 3;
   column-width: 150px;
   -webkit-column-width: 150px;
   -moz-column-width: 150px;   
   -webkit-column-count: 3;
   -moz-column-count: 3;   
}
#PageBody.stateMapLane #listingLaneCities a,
#PageBody.stateMapLane #listingLaneCounties a {
   line-height: 25px;
}
#PageBody.stateMapLane .statePagination {
   float: left;
   margin: -14px 0px 20px 32px;
}
#PageBody.Standard2LaneRight.fineAHomeStateDetails #BodyRightLane {
   display: none;
}
#PageBody #resultMapContainer #resultMap {
   width: 100% !important;
}
#PageBody #resultMapContainer {
   width: 735px;
   margin-top: 10px;
}
#PageBody #resultMapContainerBtm {
   width: 100%;
}

/* SCHOOL SEARCH */
#PageBody.fahSchoolBody .locationRequiredInfo {
   position: relative;
   margin: 5px 0px 0px 300px;
}

/* ESPANOL FAH */
body.esp #PageBody.fahSchoolBody #findASchoolFeaturesSearch .featureList {
   height: 245px;
}
body.esp #PageBody.fahSchoolBody #findASchoolSearch {
   margin: 10px 0px 0px 160px;
}
body.esp #PageBody.fahSchoolBody .findASchoolSearchBtn {
   left: 22px;
}
body.esp #PageBody.fahSchoolBody #findAHomeBySchoolBody #noSchoolSearchResultsSection .actionSection {
   margin: 40px 0px 0px 192px;
}
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .schoolSelectInfo .info {
   position: absolute;  
}
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .schoolSelectInfo {
   margin: 12px 0px 0px 8px;
}
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .gradeSpanCode {
   font-size: 10px;
}
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .gradeSpanCode .infoLabel {
   font-size: 12px;
}
body.esp #PageBody.stateMapLane .statedetailSitemapLane #stateInformationDiv .stateInfoCol1, 
body.esp #PageBody.stateMapLane .statedetailSitemapLane #stateInformationDiv .stateInfoCol2 {
   width: 212px;
}
body.esp #PageBody.stateMapLane .searchDiv .searchBtn {
   margin: 15px 142px;
}
